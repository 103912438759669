.app__footer{
    flex: 1;
    width: 100%;
    flex-direction: column;

    .app__footer-cards{
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;

        margin: 4rem 2rem 2rem;

        .app__footer-card{
            min-width: 290px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;

            margin: 1rem 0;
            padding: 1rem;
            border-radius: 10px;
            cursor: pointer;
            background-color: var(--white-color);

            transition: all 0.3s ease-in-out;

            svg{
                width: 40px;
                height: 40px;
                margin: 0 0.7rem;
                color: var(  --gray-color);
            }

            img{
                width: 40px;
                height: 40px;
                margin: 0 0.7rem;
            }

            p{
                font-weight: 600;
            }

            a{
                text-decoration: none;
                font-weight: 500;
            }

            &:hover{
                box-shadow: 0 0 15px #fef4f5;
            }
        }

        @media screen and (min-width: 2000px){
            width: 40%;
        }
        
        @media screen and (max-width: 450px){
            margin: 2rem 0rem 0rem;
            width: 100%;
        }
    }

    @media screen and (max-width: 768px){
        width: 100%;
    }

}

.app__footer-form{
    width: 60%;
    flex-direction: column;
    margin: 1rem 2rem;

    div{
        width: 100%;
        margin: 0.75rem 0;
        border-radius: 10px;
        cursor: pointer;
        background-color: var(--primary-color);
        
        transition: all 0.3s ease-in-out;

        input, textarea{
            width: 100%;
            padding: 0.95rem;
            border: none;
            border-radius: 7px;
            background-color: var(--white-color);

            font-family: var(--font-base);
            color: var(--gray-color);
            outline: none;
        }

        textarea{
            height: 170px;;
        }

        &:hover{
            box-shadow: 0 0 25px var(--primary-color);
        }
    }

    button{
        padding: 1rem 2rem;
        border-radius: 10px;
        border: none;
        background-color: var(--secondary-color);
        font-weight: 500;
        color: var(--white-color);
        outline: none;
        margin: 2rem 0 0 0;
        font-family: var(--font-base);
        cursor: pointer;
    }

    @media screen and (min-width: 2000px){
        width: 40%;
    }

    @media screen and (max-width: 767px){
        width: 100%;
        margin: 1rem, 0;
    }
}

.footer__social {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
  
    padding: 1rem;
  
    div {
      width: 40px;
      height: 40px;
      border-radius: 10px;
      background-color: var(--white-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      a{
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--gray-color);
      }
  
      &:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
  
        svg {
          color: var(--white-color);
        }
      }
  
      @media screen and (max-width: 1200px) {
        width: 70px;
        height: 70px;
  
        margin: 0.5rem 0;
  
        svg {
          width: 30px;
          height: 30px;
        }
      }
    }
  
    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  @media screen and (min-width: 2000px) {
    .head-text{
        font-size: 6rem;
    }
  }

  

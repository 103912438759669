#home{
    position: relative;
    background: url('../../assets/circles.svg');
    background-size: cover;
    background-repeat: repeat;
    background-position: center;

    .app__wrapper{
        padding: 0;

        .copyrigth{
            display: none;
        }
    }
}

.app__header{
    flex: 1;
    max-width: 100%;
    height: 100%;
    flex-direction: row;

    padding: 6rem 2rem 0;

    @media screen and (min-width: 2000px){
        padding-top: 8rem;
    }

    @media screen and (max-width: 1200px){
        flex-direction: column;
        
    }

    @media screen and (max-width: 400px){
        padding: 6rem 1rem 2rem;
    }
}

.app__header-info{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    @media screen and (min-width: 2000px){
        width: 100%;
        height: 100%;
        margin-right: 0rem;
        margin-top: 10rem;
    }

    @media screen and (min-width: 1200px){
        flex: 0.65;
        height: 50%;
    }
}

.app__header-badge{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    .badge-cmp, .tag-cmp{
        padding: 1rem 2rem;
        background: rgba(134, 160, 252, 1);
        border-radius: 15px;
        flex-direction: row;
        width: auto;

        box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

        @media screen and (min-width: 2000px){
            padding: 2rem 4rem;
        }
        @media screen and (min-width: 1200px){
            margin-right: 1rem;
        }
    }

    .greeting{
        @media screen and (min-width: 2000px){
            font-size: 2rem;
        }
    }

    .tag-cmp{
        flex-direction: column;
        margin-top: 3rem;

        p{
            width: 100%;
            text-transform: uppercase;
            text-align: right;
        }
    }

    span{
        font-size: 2.5rem;

        @media screen and (min-width: 2000px){
            font-size: 5rem;
        }
    }

    @media screen and (max-width: 1200px){
        justify-content: flex-start;
        align-items: flex-start;
    }
}


.app__header-circles{
    flex: 0.75;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    height: 100%;
    margin-left: 1rem;

    div{
        width: 100px;
        height: 100px;
        border-radius: 50%;

        background: var(--secondary-background);
        box-shadow: 0px 0px 20px rgba(0,0,0,0.1);

        svg{
            width: 60%;
            height: 60%;
            color: var(--lightGray-color);
        }

        img{
            width: 60%;
            height: 60%;
        }
    }

    div:nth-child(1){
        width: 100px;
        height: 100px;
    }

    div:nth-child(2){
        margin: 1.75rem;
        width: 150px;
        height: 150px;
    }

    div:nth-child(3){
        width: 70px;
        height: 70px;
    }

    @media screen and (min-width: 2000px){
        margin-left: 4rem;

        div:nth-child(1){
            width: 300px;
            height: 300px;
        }
    
        div:nth-child(2){
            width: 400px;
            height: 400px;
        }
    
        div:nth-child(3){
            width: 260px;
            height: 260px;
        }

    }

    @media screen and (max-width: 1200px){
        width: 100%;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 0;

        div{
            margin: 1rem;
        }
    }
    
}

.app__header-img{
    flex: 1;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    img{
        width: 100%;
        object-fit: contain;
        position: relative;
        z-index: 1;
    }

    .profile-me{
        height: 300px;
        width: 300px;
        border-radius: 50%;
        object-fit: cover;
        flex-shrink: 0;
        border: 3px solid var(--white-color);
    }



    .overlay_circle{
        position: absolute;
        right: 0;
        left: 0;
        bottom: 0;
        z-index: 0;
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 1200px){
        margin: 2rem 0;
    }

    @media screen and (min-width: 768px){
        .profile-me{
            height: 500px;
            width: 500px;
        }
    }

    @media screen and (min-width: 2000px){
        .profile-me{
            height: 800px;
            width: 800px;
        }
    }
}



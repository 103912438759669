.app__projects{
    flex: 1;
    width: 100%;
    flex-direction: column;
}


.app__project-filter {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
  
    margin: 4rem 0 2rem;
  
    .app__project-filter-item {
      padding: 0.5rem 1rem;
      border-radius: 0.5rem;
      background-color: #fff;
      font-weight: 800;

      cursor: pointer;
      transition: all 0.3s ease;
      margin: 0.5rem;
  
      &:hover {
        background-color: var(--secondary-color);
        color: #fff;
      }
  
      @media screen and (min-width: 2000px) {
        padding: 1rem 2rem;
        border-radius: 0.85rem;
      }
    }
  
    .item-active {
      background-color: var(--secondary-color);
      color: #fff;
    }
  }

  .app__project-portfolio{
      display: flex;
      flex-flow: row wrap;
      justify-content: center;

      .app__project-item{
          width: 270px;
          flex-direction: column;
          justify-content: flex-start;
          margin: 2rem;
          padding: 1rem;
          border-radius: 0.5rem;
          background-color: #fff;
          color: #000;

          cursor: pointer;
          transition: all 0.3s ease;

          &:hover{
              box-shadow: 0 0 25px rgba(0,0,0,0.2);
          }

          @media screen and (min-width: 2000px) {
            width: 470px;
            padding: 1.25rem;
            border-radius: 0.75rem;
          }

          @media screen and (max-width: 300px) {
            width: 100%;
            padding: 1rem;
          }
              
      }
  }

  .app__project-img{
      width: 100%;
      height: 230px;
      position: relative;

      img{
            width: 100%;
            height: 100%;
            border-radius: 0.5rem;
            object-fit: cover;
      }

      @media screen and (min-width: 2000px) {
        height: 350px;
      }
          

  }

  .app__project-hover{
      justify-content: space-around;
      position: absolute;
      top:0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;

      background-color: rgba(0,0,0,0.5);
      border-radius: 0.5rem;
      opacity: 0;
      transition: all 0.3s ease;

      div{
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background-color: rgba(0,0,0,0.5);
          color: #fff;

          margin: 1;
          font-family: var(--font-base);
          font-weight: 800;

          cursor: pointer;
          transition: all 0.3s ease;

          svg{
              width: 50%;
              height: 50%;
              color: var(--white-color);
          }
      }

      @media  screen and(max-width: 768px) {
        display: none;
      }
  }

  .app__project-content{
      padding: 0.5rem;
      width: 100%;
      position: relative;
      flex-direction: column;

      h4{
          margin-top: 1rem;
          line-height: 1.5;
      }

      .app__project-tag{
          position: absolute;
          padding: 0.5rem 1rem;
          border-radius: 10px;
          background: rgba(0,0,0,0.6);
          top: -25px;
      }

      .app__project-title{
        font-weight: bold;
        padding-top: .75rem;

        @media screen and (min-width: 2000px) {
          padding-top: 2rem;
        }
      }
  }

  .app__project-links{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    padding: .5rem;
    width: 90%;

    div {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--lightGray-color);
      margin: 0.25rem 0;
      border: 1px solid var(--lightGray-color);
  
      display: flex;
      justify-content: center;
      align-items: center;
  
      transition: all 0.3s ease-in-out;
  
      a{
        display: flex;
        justify-content: center;
        align-items: center;
      }
  
      svg {
        width: 15px;
        height: 15px;
        color: var(--black-color);
      }
    }

    @media screen and (min-width: 1200px) {
      display: none;
    }
  }

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    .backdrop-blur {
      background-color: rgba(37, 38, 42, .9);
      -webkit-backdrop-filter: blur(4px);
      backdrop-filter: blur(4px);
    }
}